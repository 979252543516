<template>
  <div class="content">
    <div class="col-md-12 mt-1">
      <div class="row">
        <div class="col-lg col-md-6">
          <div class="card card-stats">
              <div class="card-body card-logo">
                <div class="row">
                  <div class="col-12">
                    <!-- <img src="img/logo_cleint_mg/logo.png" alt="" /> -->
                    <img src="img/logo_cleint_mg/logo-mg-mobile.jpg" alt="" />
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="col-lg col-md-6" v-for="stat in Stats_by_products">
          <div class="card card-stats mongustave">
            <div class="card-body card-stat">
              <div class="row">
                <div class="col-5">
                  <div class="info-icon text-center icon-primary mt-2">
                    <i :class="stat.icon"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <h2 class="card-category">MER</h2>
                    <label class="card-title">
                      <animated-number
                        :value="stat.mer"
                        :formatValue="formatMER"
                        :duration="2000"
                      />
                    </label>(
                    <label class="taux-mer ml-1 mr-1" :style="(stat.taux_mer < 30) ? 'color: red' : 'color: green'">
                      <animated-number
                        :value="stat.taux_mer"
                        :formatValue="formatTaux"
                        :duration="1000"
                      />
                    </label>)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mg-table-stats">
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
              <i class="fas fa-heartbeat icon-large"></i>
                (top 5 clients)
            </span>
            <div class="card-body">
              <el-table row-class-name="booking-table-realtime-stats-rows" :header-cell-style="tableHeaderStyle" :data="Stats_health_by_client">
                <el-table-column
                  prop='name_client'
                  label="Client"
                  minWidth='185'
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop='imp'
                  label="cotations"
                  minWidth='170'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.imp) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop='mer_number'
                  label="MER"
                  minWidth='100'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.mer_number) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
              <i class="fas fa-paw icon-large"></i>
              (top 5 clients)
            </span>
            <div class="card-body">
              <el-table row-class-name="booking-table-realtime-stats-rows" :header-cell-style="tableHeaderStyle" :data="Stats_animal_by_client">
                <el-table-column
                  prop='name_client'
                  label="Client"
                  minWidth='185'
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop='imp'
                  label="cotations"
                  minWidth='170'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.imp) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop='mer_number'
                  label="MER"
                  minWidth='100'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.mer_number) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mg-table-stats">
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
              <i class="fas fa-home icon-large"></i>
              (top 5 clients)
            </span>
            <div class="card-body">
              <el-table row-class-name="booking-table-realtime-stats-rows" :header-cell-style="tableHeaderStyle" :data="Stats_home_by_client">
                <el-table-column
                  prop='name_client'
                  label="Client"
                  minWidth='185'
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop='imp'
                  label="cotations"
                  minWidth='170'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.imp) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop='mer_number'
                  label="MER"
                  minWidth='100'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.mer_number) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
              <i class="fas fa-car icon-large"></i>
              (top 5 clients)
            </span>
            <div class="card-body">
              <el-table row-class-name="booking-table-realtime-stats-rows" :header-cell-style="tableHeaderStyle" :data="Stats_car_by_client">
                <el-table-column
                  prop='name_client'
                  label="Client"
                  minWidth='185'
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop='imp'
                  label="cotations"
                  minWidth='170'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.imp) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop='mer_number'
                  label="MER"
                  minWidth='100'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.mer_number) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mg-table-stats">
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
              <i class="tim-icons icon-bank icon-large"></i>
              (top 5 clients)
            </span>
            <div class="card-body">
              <el-table row-class-name="booking-table-realtime-stats-rows" :header-cell-style="tableHeaderStyle" :data="Stats_emprunt_by_client">
                <el-table-column
                  prop='name_client'
                  label="Client"
                  minWidth='185'
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  prop='imp'
                  label="cotations"
                  minWidth='170'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.imp) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop='mer_number'
                  label="MER"
                  minWidth='100'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.mer_number) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        
      </div>

    </div>
  </div>
</template>
  
  <script>
  import { Table, TableColumn } from 'element-ui';
  import gql from 'graphql-tag'
  import PerfectScrollbar from 'perfect-scrollbar';
  import moment from 'moment'
  import AnimatedNumber from "animated-number-vue";

  export default {
    apollo: {
      ReelTimeStats: {
        client: 'MARKET',
        query: gql `
          query ($date_debut: String!, $date_fin: String!) {
            Stats_product:getStatisticsMyGustaveProducts(date_debut: $date_debut, date_fin: $date_fin) {
              affichage_sante
              affichage_animaux
              mer_sante
              mer_animaux
              ab_sante
              ab_animaux
              affichage_mrh
              mer_mrh    
              ab_mrh
              affichage_auto
              mer_auto
              ab_auto
              affichage_emprunteur
              mer_emprunteur
              ab_emprunteur
            }
            Stats_client_health:getStatisticsMyGustaveHealthClients(date_debut: $date_debut, date_fin: $date_fin) {
              name_client
              mer_number
              imp
            }
            Stats_client_animal:getStatisticsMyGustaveAnimalsClients(date_debut: $date_debut, date_fin: $date_fin) {
              name_client
              mer_number
              imp
            }
            Stats_client_mrh:getStatisticsMyGustaveMRH(date_debut: $date_debut, date_fin: $date_fin) {
             name_client
              mer_number
              imp
            } 
            Stats_client_auto:getStatisticsMyGustaveAUTO(date_debut: $date_debut, date_fin: $date_fin) {
             name_client
              mer_number
              imp
            } 
            Stats_client_emprunteur:getStatisticsMyGustaveEmprunteur(date_debut: $date_debut, date_fin: $date_fin) {
             name_client
              mer_number
              imp
            } 
          }
        `,
        variables () {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            date_debut: moment().format("DD-MM-YYYY"),
            date_fin:  moment().format("DD-MM-YYYY")
          }
        },
        result({ data, loader, networkStatus }) {
          this.Stats_by_products[0].mer = data.Stats_product.mer_sante
          this.Stats_by_products[0].taux_mer = ((data.Stats_product.mer_sante / data.Stats_product.affichage_sante) * 100).toFixed(0)
          this.Stats_by_products[1].mer = data.Stats_product.mer_animaux
          this.Stats_by_products[1].taux_mer = ((data.Stats_product.mer_animaux / data.Stats_product.affichage_animaux) * 100).toFixed(0)
          this.Stats_by_products[3].mer = data.Stats_product.mer_mrh
          this.Stats_by_products[3].taux_mer = data.Stats_product.affichage_mrh !=0 ? ((data.Stats_product.mer_mrh / data.Stats_product.affichage_mrh) * 100).toFixed(0) : 0
          this.Stats_by_products[2].mer = data.Stats_product.mer_auto
          this.Stats_by_products[2].taux_mer = data.Stats_product.affichage_auto !=0 ? ((data.Stats_product.mer_auto / data.Stats_product.affichage_auto) * 100).toFixed(0) : 0
          this.Stats_by_products[4].mer = data.Stats_product.mer_emprunteur
          this.Stats_by_products[4].taux_mer = data.Stats_product.affichage_emprunteur !=0 ? ((data.Stats_product.mer_emprunteur / data.Stats_product.affichage_emprunteur) * 100).toFixed(0) : 0

          /// stats client:
          this.Stats_health_by_client = data.Stats_client_health.sort((a, b) => b.mer_number - a.mer_number).slice(0, 5)
          this.Stats_animal_by_client = data.Stats_client_animal.sort((a, b) => b.mer_number - a.mer_number).slice(0, 5)
          this.Stats_home_by_client = data.Stats_client_mrh.filter(item => { return item.imp != 0 }).sort((a, b) => b.mer_number - a.mer_number).slice(0, 5)
          this.Stats_car_by_client = data.Stats_client_auto.sort((a, b) => b.mer_number - a.mer_number).slice(0, 5)
          this.Stats_emprunt_by_client = data.Stats_client_emprunteur.sort((a, b) => b.mer_number - a.mer_number).slice(0, 5)

          // this.$apollo.queries.ReelTimeStats.startPolling(900000)
          this.$apollo.queries.ReelTimeStats.startPolling(300000)
        },
        update: data => data.ReelTimeStats,
      },
    },
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      AnimatedNumber
    },
    data() {
      return {
        fullscreen: false,
        PerfectScrollbar: null,
        StartDate: null,
        EndnDate: null,
        searchQuery: '',
        GlobalTable: [],
        AnimauxTable: [],
        MrhTable: [],
        AutoTable: [],
        EmprunteurTable: [],
        SanteTable: [],
        total: 0,
        SkipQuery_Sante: true,
        SkipQuery_Animaux: true,
        SkipQuery_Mrh: true,
        SkipQuery_Auto: true,
        SkipQuery_Emprunteur: true,
        Stats_by_products: [
          {
            mer: '',
            taux_mer: '',
            icon: "fas fa-heartbeat",
            label: "SANTÉ"
          },
          {
            mer: '',
            taux_mer: '',
            icon: "fas fa-paw",
            label: "ANIMAUX"
          },
          {
            mer: '',
            taux_mer: '',
            icon: "fas fa-car",
            label: "AUTO"
          },
          {
            mer: '',
            taux_mer: '',
            icon: "fas fa-home",
            label: "HABITATION"
          },
          {
            mer: '',
            taux_mer: '',
            icon: "tim-icons icon-bank",
            label: "EMPRUNTEUR"
          },
        ],
        Stats_health_by_client: [],
        Stats_animal_by_client: [],
        Stats_home_by_client: [],
        Stats_car_by_client: [],
        Stats_emprunt_by_client: []
      }
    },
    methods: {
      formatMER(value) {
        return `${value.toFixed(0)}`;
      },
      formatTaux(value) {
        return `${value.toFixed(0)} %`;
      },
      toggle (div) {
        this.$fullscreen.toggle(this.$el.querySelector(div), {
          wrap: false,
          callback: this.fullscreenChange
        })
      },
      fullscreenChange (fullscreen) {
        this.fullscreen = fullscreen
        if (fullscreen) {
          this.PerfectScrollbar = new PerfectScrollbar(".ListSanteTable");
        }
        else {
          this.PerfectScrollbar.destroy();
          this.PerfectScrollbar = null
        }
      },
      search() {
        this.SkipQuery_Global = false
        this.SkipQuery_Sante = false
        this.SkipQuery_Animaux = false
        this.SkipQuery_Mrh = false
        this.SkipQuery_Auto = false
        this.SkipQuery_Emprunteur = false
      },
      statusAnnimauxChange(event, row) {
        if (row.status == 0) {
          row.status = 1
        } else if (row.status == 1) {
          row.status = 0
        } 
      },
      statusSanteChange(event, row) {
        if (row.status == 0) {
          row.status = 1
        } else if (row.status == 1) {
          row.status = 0
        } 
      },
      formatThounsends: function(value, fixe = false, number = 0){
        value != null ? value = value :value = 0
        if(fixe){
          value = value.toFixed(number)
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
      tableHeaderStyle({row, column, rowIndex, columnIndex }) {
        if (rowIndex === 0) {
          return `font-size: 16px;`;
        }
      },
    },
    mounted() {
      window.setInterval(() => {
        location.reload()
      }, 10800000 )
    }, 
  }
  </script>

<style>

  .booking-table-realtime-stats-rows {
    text-transform: uppercase;
    /* font-weight: bold; */
    font-size: 20px;
  }

</style>

<style scoped>
.mg-table-stats {
  margin-top: -0.5%;
}

.card-logo {
  background: #f9f9fb;
  border-radius: 0.2857rem;
  height: 92px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
}
.card-stats.mongustave .info-icon.icon-primary {
  background: #ff6b00;
}

.card-stats.mongustave .info-icon i {
  padding: 0px;
  color: #fff;
  font-size: 32px;
  line-height: 50px;
}

.card-stats .numbers .card-category {
  font-size: 18px;
  font-family: "Fira Sans Condensed", sans-serif;
  color: #fff;
}

.card-stats.mongustave .card-title {
  font-size: 30px;
  font-family: "Fira Sans Condensed", sans-serif;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0px;
}

.card-stats.mongustave .numbers {
  text-align: center;
}

.card-header.mongustave {
  position: relative;
  background: #217fb2; /* fallback color if gradients are not supported*/
  background: -webkit-linear-gradient(100deg, #217fb2, #0cb3c6);
  background: -moz-linear-gradient(100deg, #217fb2, #0cb3c6);
  background: -o-linear-gradient(100deg, #217fb2, #0cb3c6);
  background: linear-gradient(100deg, #217fb2, #0cb3c6);
}

.card .card-header:not([data-background-color]) {
  background-color: none;
}

.card .card-header {
  color: #fff;
  padding: 12px 0px 11px 15px;
}

.col_fas {
  color: #fff;
  font-size: 35px;
  width: 51px;
  height: 51px;
  background: #203b93;
  border-radius: 100%;
  margin: auto;
  line-height: 51px;
  border: 2px solid #e96d12;
}

.card-header.mongustave {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 24px;
  line-height: 29px;
}

.card-header.mongustave strong {
  font-weight: 700;
  font-size: 30px;
}

.col_white {
  padding: 0px;
  font-family: "Fira Sans Condensed", sans-serif;
  color: #fff;
  border: 3px solid #fff;
  font-size: 14px;
  margin: auto;
  line-height: 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.col_white strong {
  font-size: 30px;
}

.taux-mer {
  font-weight: bold;
}

</style>