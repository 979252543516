<template>
  <div class="content">
    <div class="col-md-12 mt-2">
      <center>
        <img src="img/logo-weedomarket.png" alt="" />
      </center>
      <div class="card listcategories mt-2">
        <div class="card-body">
          <el-table :data="searchCategorie" :header-cell-style="tableHeaderStyle" v-loading="$apollo.queries.categories.loading" row-class-name="booking-table-realtime-stats-rows" show-summary :summary-method="getSummaries" class="table-realtime-wm">
            <el-table-column
              prop='categorie'
              :label="$t('table.columns.Product')"
              minWidth='100'
              show-overflow-tooltip
              >
              <template scope="scope">
                <i style="font-size: 30px !important;" :class="icons[scope.row.categorie.replace(/Ã©/g,'é')] + ' icon-large'" :title="scope.row.categorie.replace(/Ã©/g,'é').toUpperCase()"></i>
              </template>
            </el-table-column>
            <el-table-column
              prop='leads_collectes'
              :label="$t('table.columns.Collected')"
              minWidth='115'
              show-overflow-tooltip
              >
              <template scope="scope">
                {{ formatThounsends(scope.row.leads_collectes) }}
              </template>
            </el-table-column>
            <el-table-column
              prop='leads_nets'
              :label="$t('table.columns.CollectedNet')"
              minWidth='135'
              show-overflow-tooltip
              >
              <template scope="scope">
                {{ formatThounsends(scope.row.leads_nets) }}
              </template>
            </el-table-column>
            <el-table-column
              prop='leads_disponibles'
              :label="$t('table.columns.Available')"
              minWidth='120'
              show-overflow-tooltip
              >
              <template scope="scope">
                {{ formatThounsends(scope.row.leads_disponibles) }}
              </template>
            </el-table-column>
            <el-table-column
              prop='leads_vendus'
              :label="$t('table.columns.sold')"
              minWidth='150'
              show-overflow-tooltip
              >
              <template scope="scope">
                {{ formatThounsends(scope.row.leads_vendus) + " - " }}
                {{ (scope.row.leads_collectes != 0) ? formatThounsends((scope.row.leads_vendus * 100) / scope.row.leads_collectes, true, 2) : '0' }} %
              </template>
            </el-table-column>
            <el-table-column
              prop='leads_vendus_trois_fois'
              :label="$t('table.columns.Sold3Times')"
              minWidth='135'
              show-overflow-tooltip
              >
              <template scope="scope">
                {{ formatThounsends(scope.row.leads_vendus_trois_fois) + " - " }}
                {{ (scope.row.leads_collectes != 0) ? formatThounsends((scope.row.leads_vendus_trois_fois * 100) / scope.row.leads_collectes, true, 2) : '0' }} %
              </template>
            </el-table-column>
            <el-table-column
              prop='leads_invendus'
              :label="$t('table.columns.Unsold')"
              minWidth='115'
              show-overflow-tooltip
              >
              <template scope="scope">
                {{ formatThounsends(scope.row.leads_invendus) }}
              </template>
            </el-table-column>
            <el-table-column
              prop='leads_devalides'
              :label="$t('table.columns.InvalidationRate')"
              minWidth='140'
              show-overflow-tooltip
              >
              <template scope="scope">
                {{ (scope.row.leads_vendus != 0) ? formatThounsends((scope.row.leads_devalides * 100) / scope.row.leads_vendus, true, 2) : '0' }} %
              </template>
            </el-table-column>
            <el-table-column
              prop='ca_realise'
              :label="$t('table.columns.GrossRealizedTurnover')"
              minWidth='145'
              show-overflow-tooltip
              >
              <template scope="scope">
                {{ formatThounsends(scope.row.ca_realise, true, 2) }} €
              </template>
            </el-table-column>
            <el-table-column
              prop='ca_realise_net'
              :label="$t('table.columns.NetTurnover')"
              minWidth='140'
              show-overflow-tooltip
              >
              <template scope="scope">
                {{ formatThounsends(calculate_CaNet(scope.row), true, 2) }} €
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Select, Option, DatePicker } from 'element-ui'
import { Table, TableColumn } from 'element-ui'
import Fuse from 'fuse.js';
import gql from 'graphql-tag'
import { Icons } from "src/pages/Pages/MyMarketAdmin/Product_Icons";
import { BaseTable, BaseAlert } from 'src/components';
import moment from 'moment'


let today = new Date()

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseTable,
    BaseAlert
  },
  apollo: {
    categories: {
      client: 'MARKET',
      query: gql` query ($date_debut: String, $date_fin: String, $gamme: String) {
        categoriesStats (date_debut: $date_debut, date_fin: $date_fin, gamme: $gamme) {
          taux_mer_sante
          taux_mer_animaux
          taux_mer_mrh
          taux_mer_auto
          taux_mer_emprunteur
          taux_mer_creditconso
          resumes {
            categorie_id
            categorie
            leads_collectes
            leads_livres
            ca_paye
            ca_realise
            ca_realise_net
            leads_devalides
            leads_nets
            leads_vendus
            leads_vendus_trois_fois
            leads_invendus
            leads_disponibles
          }
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.ListCategorie = this.searchCategorie = data.categoriesStats.resumes
        this.fuseSearch = new Fuse(this.ListCategorie, this.options);

        this.$apollo.queries.categories.startPolling(900000)
      },
      variables () {
        return {
          date_debut: moment().format("YYYY-MM-DD"),
          date_fin: moment().format("YYYY-MM-DD"),
          gamme: ''
        }
      },
      update: data => data.categoriesStats
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.ListCategorie;
      if (value !== '' && this.fuseSearch) {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchCategorie = result;
    }
  },
  data() {
    return {
      loadingLeadsDetails: false,
      ShowDetailsLeadsModal: false,
      ListDetailsLeads: [],
      fullscreen: false,
      icons: Icons,
      SkipQuery: true,
      ListCategorie: [],
      searchCategorie: [],
      StartDate: null,
      EndnDate: null,
      searchQuery: '',
      fuseSearch: null,
      options : {
        // findAllMatches: true,
        threshold: 0.2,
        keys: [
          "categorie",
          "leads_collectes",
          "leads_livres",
          "ca_paye",
          "ca_realise",
          "ca_realise_net",
          "leads_devalides",
          "leads_nets",
          "leads_vendus",
          "leads_vendus_trois_fois",
          "leads_invendus",
          "leads_disponibles",
        ]
      }
    }
  },
  methods: {
    toggle () {
      this.$fullscreen.toggle(this.$el.querySelector('.listcategories'), {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange (fullscreen) {
      this.fullscreen = fullscreen
    },
    search () {
      this.SkipQuery = false
    },
    calculate_CaNet(row) {
      let ca_net = 0
      if (row.categorie_id === '1') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_sante
      }
      else if (row.categorie_id === '4') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_animaux
      }
      else if (row.categorie_id === '3') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_mrh
      }
      else if (row.categorie_id === '2') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_auto
      }
      else if (row.categorie_id === '12') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_emprunteur
      }
      else if (row.categorie_id === '100') {
        ca_net = row.ca_realise_net + this.categories.taux_mer_creditconso
      }
      else {
        ca_net = row.ca_realise_net
      }
      return ca_net
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getSummaries(param) {
      const { columns, data } = param;
        let somme_leads_collectes = 0
        let somme_leads_nets = 0
        let somme_leads_disponibles = 0
        let somme_leads_vendus = 0
        let somme_leads_vendus_trois_fois = 0
        let somme_leads_invendus = 0
        let somme_leads_devalides = 0
        let somme_ca_realise = 0
        let somme_ca_realise_net = 0
        data.forEach(stats => {
          somme_leads_collectes += stats.leads_collectes
          somme_leads_nets += stats.leads_nets
          somme_leads_disponibles += stats.leads_disponibles
          somme_leads_vendus += stats.leads_vendus
          somme_leads_vendus_trois_fois += stats.leads_vendus_trois_fois
          somme_leads_invendus += stats.leads_invendus
          somme_leads_devalides += stats.leads_devalides
          somme_ca_realise += stats.ca_realise
          somme_ca_realise_net += stats.ca_realise_net
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "";
            return;
          } else if (index === 1) {
            sums[index] = this.formatThounsends(somme_leads_collectes);
            return;
          } else if (index === 2) {
            sums[index] = this.formatThounsends(somme_leads_nets);
            return;
          } else if (index === 3) {
            sums[index] = this.formatThounsends(somme_leads_disponibles);
            return;
          } else if (index === 4) {
            sums[index] = this.formatThounsends(somme_leads_vendus) + " - " + ((somme_leads_collectes != 0) ? this.formatThounsends((somme_leads_vendus * 100) / somme_leads_collectes, true, 2) : '0') + " %";
            return;
          } else if (index === 5) {
            sums[index] = this.formatThounsends(somme_leads_vendus_trois_fois) + " - " + ((somme_leads_collectes != 0) ? this.formatThounsends((somme_leads_vendus_trois_fois * 100) / somme_leads_collectes, true, 2) : '0') + " %";
            return;
          } else if (index === 6) {
            sums[index] = this.formatThounsends(somme_leads_invendus);
            return;
          } else if (index === 7) {
            sums[index] = ((somme_leads_vendus != 0) ? this.formatThounsends((somme_leads_devalides * 100) / somme_leads_vendus, true, 2) : '0') + " %";
            return;
          } else if (index === 8) {
            sums[index] = this.formatThounsends(somme_ca_realise, true, 2) + " €";
            return;
          } else if (index === 9 && this.categories) {
            sums[index] = this.formatThounsends(somme_ca_realise_net + this.categories.taux_mer_sante + this.categories.taux_mer_animaux + this.categories.taux_mer_mrh + this.categories.taux_mer_auto + this.categories.taux_mer_emprunteur + this.categories.taux_mer_creditconso, true, 2) + " €";
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
    },
    tableHeaderStyle({row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return `font-size: 16px;`;
      }
    },
  },
  mounted() {
    window.setInterval(() => {
      location.reload()
    }, 3600000 )
  },
}

</script>

<style>
  .booking-table-realtime-stats-rows {
    text-transform: uppercase;
    font-size: 20px;
  }


  .table-realtime-wm .cell{
    word-break: break-word;
  }

  .table-realtime-wm .el-table__footer tr{
    font-size: 20px;
  }

  .el-table .caret-wrapper {
    width: auto
  }
</style>